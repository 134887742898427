import { env } from '@/config';

export const cleanPath = (path: string) => {
  if (typeof path !== 'string') {
    return '';
  }

  // Removes repeating slashes (e.g., //car-reviews)
  let cleanedPath = path.replace(/\/+/g, '/');

  if (!cleanedPath.startsWith('/')) {
    cleanedPath = `/${cleanedPath}`;
  }

  if (!cleanedPath.endsWith('/')) {
    cleanedPath = `${cleanedPath}/`;
  }

  return cleanedPath;
};

/**
 * Normalizes a URL by combining base URL, path segments, and query parameters.
 *
 * @param {Object} options - The options for URL normalization.
 * @param {string} [options.base=env.baseUrl] - The base URL to use. Defaults to env.baseUrl.
 * @param {Array<string|number|null|undefined|boolean>} options.paths - Array of path segments.
 * @param {Record<string, string|number|boolean|null|undefined>} [options.queryParams] - Object of query parameters.
 * @returns {string} The normalized URL string.
 */
export function normalizeUrl({
  base = env.baseUrl,
  paths,
  queryParams,
}: {
  base?: string;
  paths: Array<string | number | null | undefined | boolean>;
  queryParams?: Record<string, string | number | boolean | null | undefined>;
}) {
  // Return empty string if paths array is empty or the first path is falsy
  if (
    !paths ||
    paths.length === 0 ||
    ((!paths[0] || paths[0].toString().trim() === '') && paths.length > 1)
  ) {
    return '';
  }

  // Filter out falsy path segments and join them
  const path = paths
    .filter((path) => path && path.toString().trim() !== '')
    .join('/');

  let url: URL;
  // Use provided base URL if valid, otherwise fallback to env.baseUrl
  const _base = base && base.trim() !== '' ? base : env.baseUrl;

  try {
    // Handle case where path is already a full URL
    if (path.startsWith('http://') || path.startsWith('https://')) {
      url = new URL(path);
      url.pathname = cleanPath(url.pathname);
    } else {
      // Construct URL from base and path
      const baseUrl = new URL(_base);
      const existingParams = new URLSearchParams(baseUrl.search);
      url = new URL(baseUrl);

      // Combine base pathname with new path, clean it, and encode path segments
      const _path = cleanPath(`${baseUrl.pathname}/${path}`)
        .split('/')
        .map((p) => encodeURIComponent(p))
        .join('/');

      url.pathname = _path;

      // Preserve existing query parameters from the base URL
      existingParams.forEach((value, key) => {
        url.searchParams.set(key, value);
      });
    }
  } catch (error) {
    // Log error and return original path if URL construction fails
    console.error('Error building path:', error);
    return path;
  }

  // Add new query parameters if provided
  if (queryParams) {
    Object.entries(queryParams).forEach(([key, value]) => {
      // Only add non-null, non-undefined, non-empty string values
      if (
        value !== null &&
        value !== undefined &&
        value.toString().trim() !== ''
      ) {
        url.searchParams.set(key, value.toString());
      }
    });
  }

  // Return only pathname, search, and hash if origin matches env.baseUrl
  if (url.origin === env.baseUrl) {
    return `${url.pathname}${url.search}${url.hash}`;
  }

  // Return full URL string otherwise
  return url.toString();
}

export const hasDomain = (url: string): boolean => {
  try {
    // Use URL constructor to parse the string
    const parsedUrl = new URL(url);
    return !!parsedUrl.hostname; // Returns true if hostname is present
  } catch (error) {
    // If the URL constructor throws, it's not a valid URL
    return false;
  }
};
